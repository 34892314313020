import axios from 'axios';

const api = axios.create({
    baseURL: '/api',
    withCredentials: true,
});

api.interceptors.request.use((config) => {
    const selectedOrganization = localStorage.getItem('selectedOrganization');
    if (selectedOrganization) {
        config.headers['X-Organization-ID'] = selectedOrganization;
    }
    return config;
});

export default api;