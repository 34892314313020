import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { startRegistration } from '@simplewebauthn/browser';

const AddPasskey = () => {
    const { linkId } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkLinkValidity = async () => {
            try {
                await axios.get(`/api/users/passkeys/check-link/${linkId}`);
                setLoading(false);
            } catch (error) {
                console.error('Error checking link validity:', error);
                setError('Invalid or expired link');
                setLoading(false);
            }
        };

        checkLinkValidity();
    }, [linkId]);

    const handleAddPasskey = async () => {
        setLoading(true);
        try {
            // Get registration options from the server
            const optionsResponse = await axios.get(`/api/users/passkeys/register-options/${linkId}`);
            const options = optionsResponse.data;

            // Ensure options are correctly formatted
            if (!options || !options.publicKey) {
                throw new Error('Invalid registration options received from server');
            }

            // Start the registration process
            const registrationResponse = await startRegistration({ optionsJSON: options.publicKey });

            // Send the response to the server to complete the registration
            const verificationResponse = await axios.post(`/api/users/passkeys/register-complete/${linkId}`, registrationResponse);

            if (verificationResponse.data.verified) {
                setSuccess(true);
            } else {
                setError('Failed to verify the passkey');
            }
        } catch (error) {
            console.error('Error adding passkey:', error);
            setError(error.response?.data?.error || 'An error occurred while adding the passkey');
        } finally {
            setLoading(false);
        }
    };

    const renderContent = () => {
        if (loading) {
            return <div className="text-xl text-gray-700">Loading...</div>;
        }

        if (error) {
            return (
                <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
                    <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
                    <p className="text-gray-700">{error}</p>
                    <button
                        onClick={() => navigate('/login')}
                        className="mt-4 w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                    >
                        Go to Login
                    </button>
                </div>
            );
        }

        if (success) {
            return (
                <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
                    <h2 className="text-2xl font-bold text-green-600 mb-4">Success!</h2>
                    <p className="text-gray-700 mb-4">Passkey added successfully!</p>
                    <button
                        onClick={() => navigate('/login')}
                        className="w-full bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    >
                        Go to Login
                    </button>
                </div>
            );
        }

        return (
            <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
                <h2 className="text-2xl font-bold text-indigo-600 mb-4">Add Passkey</h2>
                <p className="text-gray-700 mb-4">Click the button below to register your passkey for this account.</p>
                <button
                    onClick={handleAddPasskey}
                    className="w-full bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                >
                    Click here to register your passkey
                </button>
            </div>
        );
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-100">
            {renderContent()}
        </div>
    );
};

export default AddPasskey;