import React, { useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

export const AuthContext = React.createContext();

const initialState = {
  isAuthenticated: null,
  organizations: [],
  user: null,
  roles: [],
  selectedOrganization: localStorage.getItem('selectedOrganization') || null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUTHENTICATED':
      return { ...state, isAuthenticated: action.payload };
    case 'SET_ORGANIZATIONS':
      return { ...state, organizations: action.payload };
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'SET_ROLES':
      return { ...state, roles: action.payload };
    case 'SET_SELECTED_ORGANIZATION':
      localStorage.setItem('selectedOrganization', action.payload);
      return { ...state, selectedOrganization: action.payload };
    case 'CLEAR_USER_DATA':
      localStorage.removeItem('selectedOrganization');
      return { ...state, isAuthenticated: false, user: null, organizations: [], roles: [], selectedOrganization: null };
    default:
      return state;
  }
};

export const AuthProvider = ({ children, navigate }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const checkAuthentication = useCallback(async () => {
    // Skip authentication check for /add-passkey route
    if (location.pathname.startsWith('/add-passkey')) {
      return;
    }

    try {
      const response = await fetch('/api/login/check', {
        method: 'GET',
        credentials: 'include',
      });

      if (response.status === 401) {
        handleUnauthorized();
        dispatch({ type: 'SET_AUTHENTICATED', payload: false });
        return;
      }

      const result = await response.json();
      dispatch({ type: 'SET_AUTHENTICATED', payload: result.logged_in });
      if (result.logged_in) {
        dispatch({ type: 'SET_USER', payload: { email: result.email } });
        dispatch({ type: 'SET_ORGANIZATIONS', payload: result.organizations });
        dispatch({ type: 'SET_ROLES', payload: result.roles });

        // If there's a selected organization in localStorage, use it
        const storedOrgId = localStorage.getItem('selectedOrganization');
        if (storedOrgId && result.organizations.some(org => org.ID === storedOrgId)) {
          dispatch({ type: 'SET_SELECTED_ORGANIZATION', payload: storedOrgId });
        } else if (result.organizations && result.organizations.length > 0) {
          // If no stored org or stored org is invalid, set the first organization as selected
          dispatch({ type: 'SET_SELECTED_ORGANIZATION', payload: result.organizations[0].ID });
        }
      }
    } catch (error) {
      console.error('Failed to check login status', error);
      dispatch({ type: 'SET_AUTHENTICATED', payload: false });
    }
  }, [location]);

  const handleUnauthorized = () => {
    const event = new CustomEvent('unauthorized', { detail: 'Unauthorized' });
    window.dispatchEvent(event);
    navigate('/login');
  };

  const clearUserData = () => {
    dispatch({ type: 'CLEAR_USER_DATA' });
  };

  const setSelectedOrganization = useCallback((orgId) => {
    dispatch({ type: 'SET_SELECTED_ORGANIZATION', payload: orgId });
  }, []);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  return (
    <AuthContext.Provider value={{ ...state, dispatch, checkAuthentication, clearUserData, setSelectedOrganization }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  navigate: PropTypes.func.isRequired,
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export default AuthContext;