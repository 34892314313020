import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { Bars3Icon, BellIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Header = ({ currentPage, setSidebarOpen }) => {
  const { dispatch, user } = useAuth();
  const navigate = useNavigate();

  const userNavigation = [
    { name: 'Your profile', href: '/profile', action: 'profile' },
    { name: 'Sign out', href: '#', action: 'signout' },
  ];

  const handleUnauthorized = () => {
    dispatch({ type: 'SET_AUTHENTICATED', payload: false });
    navigate('/login');
  };

  const handleMenuItemClick = async (event, action) => {
    event.preventDefault();
    if (action === 'signout') {
      try {
        const response = await fetch('/api/logout', {
          method: 'POST',
        });

        if (response.status === 401) {
          handleUnauthorized();
          return;
        }

        dispatch({ type: 'CLEAR_USER_DATA' });
        navigate('/login');
      } catch (error) {
        console.error('Logout failed', error);
      }
    } else if (action === 'profile') {
      navigate('/profile');
    }
  };

  return (
    <div className="lg:hidden flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button type="button" className="-m-2.5 p-2.5 text-gray-700" onClick={() => setSidebarOpen(true)}>
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      {/* Separator */}
      <div className="h-6 w-px bg-gray-200" aria-hidden="true" />

      <div className="flex flex-1 items-center gap-x-4 self-stretch lg:gap-x-6">
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900 flex items-center">{currentPage}</div>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <MenuButton className="flex items-center gap-x-2 px-3 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 w-full">
              <span className="sr-only">Your profile</span>
              <span aria-hidden="true">{user?.email}</span>
              <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {userNavigation.map((item) => (
                  <MenuItem key={item.name}>
                    {({ focus }) => (
                      <button
                        onClick={(e) => handleMenuItemClick(e, item.action)}
                        className={classNames(
                          focus ? 'bg-gray-50' : '',
                          'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                      >
                        {item.name}
                      </button>
                    )}
                  </MenuItem>
                ))}
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  currentPage: PropTypes.string.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
};

export default Header;