import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const useUnauthorizedHandler = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { dispatch } = useAuth();

    useEffect(() => {
        const handleUnauthorized = (event) => {
            if (location.pathname.startsWith('/add-passkey')) {
                // Don't redirect if we're on the add-passkey page
                return;
            }
            if (event.detail === 'Unauthorized') {
                dispatch({ type: 'SET_AUTHENTICATED', payload: false });
                navigate('/login');
            }
        };

        window.addEventListener('unauthorized', handleUnauthorized);

        return () => {
            window.removeEventListener('unauthorized', handleUnauthorized);
        };
    }, [navigate, location, dispatch]);
};

export default useUnauthorizedHandler;