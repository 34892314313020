import React, { lazy, Suspense, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import useUnauthorizedHandler from './hooks/useUnauthorizedHandler';
import Header from './components/Header';
import AddPasskey from './components/AddPasskey';
import UserEdit from './components/UserEdit';
import {
  ChartPieIcon,
  HomeIcon,
  UsersIcon,
  BuildingOffice2Icon,
  GlobeAltIcon,
  BanknotesIcon,
  WindowIcon,
} from '@heroicons/react/24/outline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = lazy(() => import('./components/Dashboard'));
const Login = lazy(() => import('./components/Login'));
const Menu = lazy(() => import('./components/Menu'));
const Transactions = lazy(() => import('./components/Transactions'));
const TransactionEdit = lazy(() => import('./components/TransactionEdit'));
const Organizations = lazy(() => import('./components/Organizations'));
const OrganizationAdd = lazy(() => import('./components/OrganizationAdd'));
const OrganizationEdit = lazy(() => import('./components/OrganizationEdit'));
const Users = lazy(() => import('./components/Users'));
const UserProfile = lazy(() => import('./components/UserProfile'));
const Domains = lazy(() => import('./components/Domains'));
const DomainAdd = lazy(() => import('./components/DomainAdd'));
const DomainEdit = lazy(() => import('./components/DomainEdit'));
const Providers = lazy(() => import('./components/Providers'));
const ProviderAdd = lazy(() => import('./components/ProviderAdd'));
const ProviderEdit = lazy(() => import('./components/ProviderEdit'));
const Sites = lazy(() => import('./components/Sites'));
const SitesAdd = lazy(() => import('./components/SitesAdd'));
const SitesEdit = lazy(() => import('./components/SitesEdit'));

const ProtectedRoute = ({ element: Component }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Component />;
};

ProtectedRoute.propTypes = {
  element: PropTypes.elementType.isRequired,
};

const App = () => {
  const { isAuthenticated, organizations, roles } = useAuth();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useUnauthorizedHandler();

  const isRoot = useMemo(() => Array.isArray(roles) && roles.includes('root'), [roles]);

  const navigation = useMemo(() => {
    const allNavItems = [
      { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: location.pathname === '/dashboard' },
      { name: 'Transactions', href: '/transactions', icon: ChartPieIcon, current: location.pathname === '/transactions' },
      { name: 'Organizations', href: '/organizations', icon: BuildingOffice2Icon, current: location.pathname === '/organizations', rootOnly: true },
      { name: 'Users', href: '/users', icon: UsersIcon, current: location.pathname === '/users', rootOnly: true },
      { name: 'Domains', href: '/domains', icon: GlobeAltIcon, current: location.pathname === '/domains' },
      { name: 'Providers', href: '/providers', icon: BanknotesIcon, current: location.pathname === '/providers' },
      { name: 'Sites', href: '/sites', icon: WindowIcon, current: location.pathname === '/sites' },
    ];

    return isRoot ? allNavItems : allNavItems.filter(item => !item.rootOnly);
  }, [location.pathname, isRoot]);

  const currentPage = navigation.find(item => item.current)?.name || 'Dashboard';

  // Check if the current route is /add-passkey
  const isAddPasskeyRoute = location.pathname.startsWith('/add-passkey');

  return (
    <div className="flex flex-col h-screen">
      {isAuthenticated && !isAddPasskeyRoute && <Header currentPage={currentPage} setSidebarOpen={setSidebarOpen} />}
      <div className="flex flex-1">
        {isAuthenticated && !isAddPasskeyRoute && <Menu navigation={navigation} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} organizations={organizations} />}
        <div className={`flex-1 flex flex-col ${isAuthenticated && !isAddPasskeyRoute ? 'lg:pl-72' : ''}`}>
          <div className="flex-1 p-10">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path='/login' element={<Login />} />
                <Route path="/add-passkey/:linkId" element={<AddPasskey />} />
                <Route path='/dashboard' element={<ProtectedRoute element={Dashboard} />} />
                <Route path="/transactions" element={<ProtectedRoute element={Transactions} />} />
                <Route path="/transactions/edit/:id" element={<ProtectedRoute element={TransactionEdit} />} />
                {isRoot && (
                  <>
                    <Route path="/organizations" element={<ProtectedRoute element={Organizations} />} />
                    <Route path="/organizations/add" element={<ProtectedRoute element={OrganizationAdd} />} />
                    <Route path="/organizations/:id" element={<ProtectedRoute element={OrganizationEdit} />} />
                    <Route path="/users" element={<ProtectedRoute element={Users} />} />
                    <Route path="/users/:id" element={<ProtectedRoute element={UserEdit} />} />
                  </>
                )}
                <Route path="/domains" element={<ProtectedRoute element={Domains} />} />
                <Route path="/domains/add" element={<ProtectedRoute element={DomainAdd} />} />
                <Route path="/domains/edit/:id" element={<ProtectedRoute element={DomainEdit} />} />
                <Route path="/providers" element={<ProtectedRoute element={Providers} />} />
                <Route path="/providers/add" element={<ProtectedRoute element={() => <ProviderAdd organizations={organizations} />} />} />
                <Route path="/providers/edit/:id" element={<ProtectedRoute element={() => <ProviderEdit organizations={organizations} />} />} />
                <Route path="/sites" element={<ProtectedRoute element={Sites} />} />
                <Route path="/sites/add" element={<ProtectedRoute element={SitesAdd} />} />
                <Route path="/sites/:id" element={<ProtectedRoute element={SitesEdit} />} />
                <Route path="/profile" element={<ProtectedRoute element={UserProfile} />} />
                <Route path='*' element={<Navigate to="/dashboard" replace />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

const AppWrapper = () => {
  const navigate = useNavigate();

  return (
    <AuthProvider navigate={navigate}>
      <App />
    </AuthProvider>
  );
};

const Root = () => (
  <Router>
    <AppWrapper />
  </Router>
);

export default Root;